// Animations
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

img[alt="quad-icon"],img[alt="left-quad-icon"],img[alt="right-quad-icon"]{
  animation: rotate linear 40s infinite backwards;
}

img[alt="spikes-icon"], img[alt="spike-left"], img[alt="spike-right"]{
  animation: rotate linear 40s infinite backwards;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap");
/*  css variables */
:root {
  // Colors
  --primary-color: #ec1c24;

  --primary-text: #fff;
  --secondary-text: #000;
  --red-text: #ec1c24;

  // Transitions
  --basic: all 0.55s ease-in-out;
  --advance: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  //test-border
  --border: 1px solid black;
}

::placeholder {
  color: var(--secondary-text);
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 10px;
}

body {
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  width: 100%;
}

//CUSTOM
.active {
  color: var(--primary-color) !important;
  font-weight: 500 !important;
}

.app{
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}