@import "../../mixins.scss";

.testimonial-section {
  @include flex();
  padding: 0 10%;
  margin-bottom: 60px;
  .left {
    flex: 1.1;
    h3 {
      @include heading2();
      margin-bottom: 30px;
      margin-left: -26px;
      // color: #333;
      color: #fff;
    }
    .testimonial-slider {
      height: 213px;
      overflow-x: visible;

      .swiper-pagination-bullets-dynamic {
        left: -46px !important;
        width: 23px;
        .swiper-pagination-bullet {
          background-color: #d9d9d9;
          opacity: 1;
          height: 12px;
          width: 12px;
          transition: var(--advance);
          margin: 4px 0;

          &:hover {
            transform: scale(1.1);
          }
        }
        .swiper-pagination-bullet-active {
          background-color: var(--primary-color) !important;
          height: 19px !important;
          width: 19px !important;
          margin-left: -3px;
        }
      }
    }
  }

  .right {
    flex: 0.9;
    position: relative;
    img[alt="spikes-icon"] {
      position: absolute;
      top: -35px;
      left: -110px;
      height: 90px;
      width: auto;
    }
  }

  @media (max-width: 913px) {
    .left {
      .testimonial-slider {
        height: 313px;
      }
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    .left {
      .testimonial-slider {
        height: 340px;
      }
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
    .left {
      .testimonial-slider {
        .swiper-pagination-bullets-dynamic {
          left: -30px !important;
        }
      }
    }
  }
}
